/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.f-normal {
  font-family: font-normal;
}

@font-face {
  font-family: "font";
  src: local("font"), url(./assets/fonts/font-bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-med";
  src: local("font-med"), url(./assets/fonts/font-med.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-normal";
  src: local("font-normal"), url(./assets/fonts/font-med.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-light";
  src: local("font-normal"),
    url(./assets/fonts/font-regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Zarid";
  src: local("Zarid"),
    url(./assets/fonts/alfont_com_29LTZaridSlab-SemiBold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "cairo";
  src: local("cairo"), url(./assets/fonts/Cairo-Bold.ttf) format("woff");
}
