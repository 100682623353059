.contact-root {
  display: flex;
  /* padding: 2rem 12.9375rem; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  padding-block: 20px;
  background: linear-gradient(278deg, #0e005d 29.74%, #004a98 96.66%);
}

.contact-box {
  display: flex;
  width: 70.125rem;
  justify-content: space-between;
  align-items: flex-start;
  max-width: -webkit-fill-available;
  max-width: -moz-available;
}

.contact-box1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  width: max-content;
}

.contact-box2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  width: max-content;
}

.contact-box3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  width: max-content;
}

.contact-box4 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.9375rem;
}

.contact-box5 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.9375rem;
}

.contact-box6 {
  display: flex;
  /* padding: 0.5rem 0rem 0.5rem 1rem; */
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  height: 18px;
}

.contact-text1 {
  display: flex;
  /* width: 7.875rem; */
  height: 1.75rem;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: right;
  text-shadow: 0px 16px 32px rgba(0, 74, 152, 0.09);
  font-size: 1.389rem;
  font-style: normal;
}

.contact-text2 {
  display: flex;
  /* width: 7.875rem; */
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: right;
  text-shadow: 0px 16px 32px rgba(0, 74, 152, 0.09);
  font-size: 1rem;
  font-style: normal;
  cursor: pointer;
  font-family: font-normal;
}

@media only screen and (max-width: 600px) {
  .contact-root {
    padding: 0;
  }
  .contact-box {
    margin-inline: 10px;
    margin-bottom: 40px;
  }
  .contact-box1 {
    width: 100%;
  }
  .contact-box2 {
    width: 100%;
  }
  .contact-box3 {
    width: 100%;
  }

  .contact-text1 {
    margin-top: 20px;
  }
}
