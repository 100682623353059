.list {
  position: relative;
  list-style: none;
  margin-left: 0;
  /* padding-left: 1.2em; */
}

.list li::before {
  content: "—";
  position: absolute;
  right: 0;
}

.check-box1 {
  display: flex;
  width: max-content;
  min-width: 100%;
  height: 100%;
  /* padding: 0px 19px 1px 76.949px; */
  justify-content: flex-start;
  align-items: center;
  /* gap: 28.834px; */
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--Gray-1, #333);
}

.check-box2 {
  display: flex;
  width: max-content;
  min-width: 100%;
  height: 100%;
  /* padding: 0px 19px 1px 76.949px; */
  justify-content: flex-start;
  align-items: center;
  /* gap: 28.834px; */
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #014a97;
  background: #f1f4ff;
}

.hero-section-memo {
  background: linear-gradient(251.98deg, #0e005d 27.17%, #004a98 83.82%);
  /* background-size: 1450px; */
  background-position: center;
  /* object-fit: cover; */
  /* width: 100vw; */
  height: 100vh;
  flex-shrink: 0;
  position: relative;
}
